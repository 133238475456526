@import './_variables.scss';

.body-containerFlex{
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    position: relative;
    > .fixed-content {
        width: 100%;
        height: 100%;
        position: fixed;
        background-color: rgba(0, 0, 0, 0.5);
        display: none;
        z-index: 999;
        &.show {
            display: block;
        }
        > .loading-content {
            width: 300px;
            height: auto;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            > span {
                > svg {
                    font-size: 30px;
                    color: $c-wh;
                    margin-bottom: 10px;
                }
            }
            > h4 {
                font-size: 24px;
                color: $c-wh;
            }
        }
    }
    
    > .main-ppal{

        // tabs ppal
        

        .tab-content{
            &.tab-steps{
                height: 100%;
                padding-bottom: 150px;
                > .tab-pane{
                    padding: 30px;
                    height: 100%;
                    overflow-y: auto;
                    overflow-x: hidden;
                    > .pg{
                        &-section{
                            width: 50%;
                            margin: 10px auto 30px;
                        }
                    }

                    .section-default{
                        width: 100%;
                        height: auto;
                        .pg-section {
                            margin: 10px auto 30px;
                        }
                    }
                }
            }
        }
    }
    .alert_required {
        font-size: 14px;
        font-weight: 700;
        font-family: 'FS Joey', Arial, Helvetica, sans-serif;
        color: $c-alert-requiered;

        .fa-exclamation-triangle, .fa-times-circle {
            font-size: 12px;
            margin: 10px 5px 10px 0;
        }
    }
}

.tooltip-inner {
    max-width: 300px;
    background: #c9ecf3;
    font-size: 14px;
    font-weight: 300;
    padding: 10px;
    border-radius: 10px;
}
