@import '../variables.scss';
.title{
    &-big{
        font-size: 35px;
        color: $c-2;
        line-height: normal;
        font-weight: bold;
    }
    &-section{
        font-size: 20px;
        color: $c-dr;
        font-weight: bold;
        opacity: .8;
        position: relative;
        padding-bottom: 15px;

        &.ppal{
            margin-top: 20px;
        }

        &::after,&::before{
            display: block;
            content: '';
            height: 3px;
            width: 50px;
            border-radius: 3px;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            
        }
        &::after{
            margin-left: -27px;
            background-color: $c-2;
        }
        &::before{
            margin-left: 27px;
            background-color: $c-3;
        }
    }
}

.tx-center{
    text-align: center;
}

.pg{
    &-section{
        font-size: 16px;
        color: rgba(0, 0, 0, 0.4);
        line-height: 1.2;
    }
    &-note{
        margin: 0 0 30px;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.5);
        line-height: 1.2;
        font-weight: bold;
        > span{
            color: $c-2;
        }
    }
}