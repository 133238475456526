@import '../variables.scss';

.modal{
    &-default{
        color: $c-gr-3;
        height: auto;
        width: 460px;
        position: fixed;
        background-color: $v-1;
        border-radius: 10px;
        top: 40%;
        left: 50%;
        transform: translate(-50%,-50%) !important;
        margin: 0;
        box-shadow: 0px 4px 12px 0px #0000002b;
        z-index: 2;

        > .modal{
            &-content{
                display: flex; 
                flex-direction: column;
                flex: 1 0 auto;
                > .modal{
                    &-header{
                        height: 35px;
                        display: flex;
                        align-items: flex-start;
                        border-bottom: 0;
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                        > .modal-title{
                            width: 100%;
                            font-size: 22px;
                            display: flex;
                            justify-content: space-between;
                            .fa-times-circle {
                                font-size: 20px;
                                color: $v-2;
                                padding: 10px;
                                cursor: pointer;
                            }
                            .fa-times-circle:hover {
                                color: $c-gr-2;
                            }
                            
                        }
                        > .close{
                            min-width: auto;
                        }
                    }

                    &-body {
                        min-height: 150px;
                        height: auto;
                        padding: 10px;
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;

                        .title-modal {
                            margin-bottom: 35px;
                            padding: 0 15px;
                            margin-top: 0;
                            font-size: 25px;
                            color: $c-1;
                            text-transform: uppercase;
                        }

                        .fa-check-circle, .fa-times-circle, .fa-info-circle {
                            font-size: 45px;
                            color: $c-2;
                            margin-bottom: 10px;
                        }

                        .error-alert {
                            color: $c-alert-error;
                        }

                        .success-alert {
                            color: $c-alert-success;
                        }

                        p {
                            font-size: 17px;
                        }

                        .modal-contact {
                            font-size: 13px;
                            margin-top: 10px;
                            line-height: normal;

                            .modal-link {
                                color: $c-1;
                                text-decoration: none;
                                margin-left: 5px;
    
                                &:hover {
                                    color: $c-2;
                                }
                            }
                        }

                        .rotate-center {
                            animation: rotate-center 0.6s ease-in-out both;
                        }

                        @keyframes rotate-center {
                            0% {
                              transform: rotate(0);
                            }
                            100% {
                              transform: rotate(360deg);
                            }
                        }

                        .heartbeat {
                            animation: heartbeat 0.8s ease-in-out 0.3s both;
                        }

                        @keyframes heartbeat {
                            from {
                              transform: scale(1);
                              transform-origin: center center;
                              animation-timing-function: ease-out;
                            }
                            10% {
                              transform: scale(0.91);
                              animation-timing-function: ease-in;
                            }
                            17% {
                              transform: scale(0.98);
                              animation-timing-function: ease-out;
                            }
                            33% {
                              transform: scale(0.87);
                              animation-timing-function: ease-in;
                            }
                            45% {
                              transform: scale(1);
                              animation-timing-function: ease-out;
                            }
                        }

                        .scale-up-center {
                            animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
                        }

                        @keyframes scale-up-center {
                            0% {
                              transform: scale(0.5);
                            }
                            100% {
                              transform: scale(1);
                            }
                        }
                    }
                    
                    &-footer{
                        display: flex;
                        justify-content: flex-end;
                        align-items: flex-end;
                        border-top: 0;
                        padding: 15px;
                        height: auto;
                    }
                }
            }
        }
    }
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.3;
}

@media (max-width: $qry-mobile-vertical){ 
    .modal{
        &-default{
            width: 310px;
    
            > .modal{
                &-content{
                    > .modal{
    
                        &-body {
                            min-height: 120px;
                            .title-modal {
                                margin-bottom: 15px;
                                font-size: 18px;
                            }
    
                            .fa-check-circle, .fa-times-circle, .fa-info-circle {
                                font-size: 35px;
                                color: $c-2;
                                margin-bottom: 10px;
                            }
    
                            .error-alert {
                                color: $c-alert-error;
                            }
    
                            .success-alert {
                                color: $c-alert-success;
                            }
    
                            p {
                                font-size: 17px;
                            }

                            .modal-link {
                                color: $c-1;
                                text-decoration: none;

                                &:hover {
                                    color: $c-2;
                                }
                            }
    
                            .rotate-center {
                                animation: rotate-center 0.6s ease-in-out both;
                            }
    
                            @keyframes rotate-center {
                                0% {
                                  transform: rotate(0);
                                }
                                100% {
                                  transform: rotate(360deg);
                                }
                            }
    
                            .heartbeat {
                                animation: heartbeat 0.8s ease-in-out 0.3s both;
                            }
    
                            @keyframes heartbeat {
                                from {
                                  transform: scale(1);
                                  transform-origin: center center;
                                  animation-timing-function: ease-out;
                                }
                                10% {
                                  transform: scale(0.91);
                                  animation-timing-function: ease-in;
                                }
                                17% {
                                  transform: scale(0.98);
                                  animation-timing-function: ease-out;
                                }
                                33% {
                                  transform: scale(0.87);
                                  animation-timing-function: ease-in;
                                }
                                45% {
                                  transform: scale(1);
                                  animation-timing-function: ease-out;
                                }
                            }
    
                            .scale-up-center {
                                animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
                            }
    
                            @keyframes scale-up-center {
                                0% {
                                  transform: scale(0.5);
                                }
                                100% {
                                  transform: scale(1);
                                }
                            }
                        }
                        
                        &-footer{
                            display: flex;
                            justify-content: flex-end;
                            align-items: flex-end;
                            border-top: 0;
                            padding: 15px;
                            height: auto;
                        }
                    }
                }
            }
        }
    }
}