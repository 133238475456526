@import '../../assets/sass/variables';

.header-ppal{
    font-family: $f-ppal;
    width: 100%;
    min-height: $sz-header;
    max-height: $sz-header;
    background-color: $c-wh;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
    > .h2-title{
        font-size: 24px;
        font-weight: 300;
        margin-left: 15px;
        color: $v-4;
        > strong{
            font-weight: bold;
            line-height: 0px;
        }
        > span {
            &.saving {
                font-size: 15px;
                color: $c-dr;
                > svg {
                    margin-left: 10px;
                }
            }
        }
    }
    >.separated-img {
        border-right: 1px solid $c-gr-2;
        padding: 0 15px;
    }
}