@import './assets/sass/styles';
@import "./assets/sass/reset";
@import "./assets/sass/mixins";
@import "./assets/sass/load-fonts";
@import "./assets/sass/variables";
@import "./assets/sass/components/text";
@import "./assets/sass/components/forms";

*{
  &:focus{
      box-shadow: none !important;
      outline: none !important;
  }
}
a{
  &:hover{
      text-decoration: none;
  }
}

html, 
body,
#root{
  font-family: 'FS Joey', Arial, Helvetica, sans-serif;
  color: $c-dr;
  width: 100%;
}

.main-ppal {
  height: 77%;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
}

.content-app{
  width: 100%;
  height: 100%;
  background-color: $c-gr;
  position: absolute;
  top: 0;
  left: 0;
  
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.container {
    width: 80%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: $qry-mobile-vertical){
  .container {
    width: 95%;
  }
  .main-ppal {
    height: 80%;
  }
}