@import '../../assets/sass/variables';

.container{
    &.nav-steps{
        padding-bottom: 20px;
        > .row{
            padding: 30px 0 0 0;
            
            > .pg{
                &-section{
                    width: 70%;
                    margin: 10px auto 30px;
                    font-family: 'Barlow', sans-serif;
                }
            }

            .section-default{
                width: 100%;
                height: auto;
                .pg-section {
                    margin: 10px auto 30px;
                }

                .rate {
                    &-conatiner {
                        display: flex;
                        flex-direction: column;
                    }
                }
            }
        }

        .section-default{
            margin-bottom: 25px;
            .fa-info-circle {
                color: $c-2;
                font-size: 16px;
                padding: 5px;
                cursor: help;
            }
            .list-title-rate {
                margin-top: 10px;
                margin-bottom: 20px;
                font-family: 'Barlow', sans-serif;
            } 
            .rate {
                &-list {
                    margin-top: 15px;
                }
                &-container {
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 10px;
                    .single-rate {
                        padding: 10px 0 0 0;
                    }
                    .rc-rate{
                        font-size: 14px;
                        .rc-rate-star {
                            line-height: 0;
                            color: $c-gr-2;
                            font-size: 14px;
                            border: 0;
                            opacity: 1;

                            .rc-rate-star-first {
                                .fa-star {
                                    font-size: 17px;
                                }
                            }

                            .rc-rate-star-second {
                                .fa-star {
                                    font-size: 17px;
                                }
                            }
                        }
                        .rc-rate-star-half .rc-rate-star-first, .rc-rate-star-full .rc-rate-star-second {
                            color: $c-2;
                        }
                        .rc-rate-star-half:hover .rc-rate-star-first, .rc-rate-star-full:hover .rc-rate-star-second {
                            color: $c-2;
                            opacity: 0.8;
                        }
                    }
                    &-column {
                        width: auto;
                        .starts-column {
                            width: auto;
                        }
                        ul {
                            li {
                                color: $c-dr;
                                height: 35px;
                                opacity: 0.8;
                                font-weight: 300;
                                font-family: 'Barlow', sans-serif;
                                font-size: 14px;
                                > .form-check-label{
                                    height: 100%;
                                    display: flex;
                                    position: relative;
                                    padding-left: 25px;
                                    margin-left: 20px;
                                    align-items: center;
                                    font-weight: 300;
                                    font-size: 14px;
                                    > input{
                                        &[type=radio]{
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            width: 100%;
                                            height: 100%;
                                            margin: 0;
                                            z-index: 8;
                                            opacity: 0;
                                            &:checked{
                                                + .control{
                                                    background-color: $c-2;
                                                    border-color: transparent;
                                                }
                
                                                ~ input{
                                                    &[type=text]{
                                                        pointer-events: all;
                                                        cursor: text;
                                                        opacity: 1;
                                                    }
                                                }
                                            }
                                        }
                                        &[type=text]{
                                            width: 100%;
                                            margin-left: 10px;
                                            z-index: 9;
                                            pointer-events: none;
                                            opacity: .6;
                                            cursor: not-allowed;
                                        }
                                    }
                
                                    > .control{
                                        width: 18px;
                                        height: 18px;
                                        position: absolute;
                                        left: 0;
                                        top: 50%;
                                        transform: translateY(-50%);
                                        background-color: transparent;
                                        border: 2px solid rgba(0, 0, 0, 0.3);
                                        display: block;
                                        border-radius: 100%;
                                        transition: all .2s;                        
                                    }
                                }

                            }
                            li:last-child {
                                border-bottom: 0;
                            }
                            .list__title {
                                font-weight: 900;
                                border: 0;
                            }
                        }
                    }
                    &-separated {
                        width: 1px;
                        margin: 30px 20px;
                        background-color: $c-dr;
                        opacity: 0.1;  
                    }
                }
            }
        }
        >section {
            .ppal {
                margin-top: 0;
            }
            .separated__info {
                display: flex;
                flex-wrap: wrap;
                padding: 25px 0 25px 0;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                margin-bottom: 15px;
                .content__info {
                    width: 33%;
                    padding-top: 10px;
                    .person {
                        font-family: 'FS Joey', sans-serif;
                        width: 100%;
                        font-size: 15px;
                        color: $c-dr;
                        opacity: 0.8;
                    
                        &-title {
                            font-weight: bold;
                        }

                        &-data {
                            font-weight: 300;
                            margin-top: 5px;
                        }
                    }
                }
            }
            .separated {
                display: flex;
                flex-direction: column;
                margin-bottom: 15px;

                .form-group {
                    width: 100%;
                }
                .table__list {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    margin-top: 15px;

                    .form-group {
                        display: flex;
                        width: 100%;
                        justify-content: flex-start;
                    }

                    &-column {
                        margin-right: 45px;
                        ul {
                            li {
                                color: $c-gr-3;
                                font-weight: 300;
                                font-family: 'Barlow', sans-serif;
                                font-size: 14px;
                            }
                            .list__title {
                                font-weight: 900;
                            }
                            .container-li {
                                display: flex;
                                flex-direction: column;
                                text-align: left;
                            }
                            .container-li-option {
                                display: flex;
                                flex-direction: column;
                                text-align: center;
                            }
                        }
                    }
                }
                &-title {
                    font-family: 'FS Joey', sans-serif;
                    font-weight: bold;
                    width: 100%;
                    color: $c-dr;
                    opacity: 0.8;
                }
            }
        }
        .container__buttons {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
        }
    }
}

@media (max-width: $qry-mobile-vertical){ 
    .container{
        &.nav-steps{
            .section-default{
                margin-bottom: 25px;
                .fa-info-circle {
                    color: $c-2;
                    font-size: 16px;
                    padding: 5px;
                    cursor: help;
                }
                .list-title-rate {
                    margin-top: 10px;
                    font-family: 'Barlow', sans-serif;
                } 
                .rate {
                    &-container {
                        display: flex;
                        flex-direction: row;
                        margin-bottom: 10px;
                        .single-rate {
                            padding: 10px 0 0 0;
                        }
                        .rc-rate{
                            font-size: 12px;
                            .rc-rate-star {
                                line-height: 0;
                                color: $c-gr-2;
                                font-size: 16px;
                                border: 0;
                                opacity: 1;
    
                                .rc-rate-star-first {
                                    .fa-star {
                                        font-size: 16px;
                                    }
                                }
    
                                .rc-rate-star-second {
                                    .fa-star {
                                        font-size: 16px;
                                    }
                                }
                            }
                            .rc-rate-star-half .rc-rate-star-first, .rc-rate-star-full .rc-rate-star-second {
                                color: $c-2;
                            }
                            .rc-rate-star-half:hover .rc-rate-star-first, .rc-rate-star-full:hover .rc-rate-star-second {
                                color: $c-2;
                                opacity: 0.8;
                            }
                        }
                        &-column-text {
                            width: 170px !important;
                        }
                        &-column {
                            width: auto;
                            .starts-column {
                                width: 135px;
                            }
                            ul {
                                li {
                                    color: $c-dr;
                                    height: 50px;
                                    opacity: 0.8;
                                    font-weight: 300;
                                    font-family: 'Barlow', sans-serif;
                                    font-size: 14px;
                                    > .form-check-label{
                                        margin-left: 5px;
                                        align-items: flex-start;
                                        > input{
                                            &[type=radio]{
                                                position: absolute;
                                                top: 0;
                                                left: 0;
                                                width: 100%;
                                                height: 100%;
                                                margin: 0;
                                                z-index: 8;
                                                opacity: 0;
                                                &:checked{
                                                    + .control{
                                                        background-color: $c-2;
                                                        border-color: transparent;
                                                    }
                    
                                                    ~ input{
                                                        &[type=text]{
                                                            pointer-events: all;
                                                            cursor: text;
                                                            opacity: 1;
                                                        }
                                                    }
                                                }
                                            }
                                            &[type=text]{
                                                width: 100%;
                                                margin-left: 10px;
                                                z-index: 9;
                                                pointer-events: none;
                                                opacity: .6;
                                                cursor: not-allowed;
                                            }
                                        }
                    
                                        > .control{
                                            width: 18px;
                                            height: 18px;
                                            position: absolute;
                                            left: 0;
                                            top: 20%;
                                            transform: translateY(-50%);
                                            background-color: transparent;
                                            border: 2px solid rgba(0, 0, 0, 0.3);
                                            display: block;
                                            border-radius: 100%;
                                            transition: all .2s;                        
                                        }
                                    }
                                }
                            }
                        }
                        &-separated {
                            width: 1px;
                            margin: 15px 10px;
                            background-color: $c-dr;
                            opacity: 0.1;  
                        }
                    }
                }
            }
            >section {
                .separated {
                    .table__list {

                        &-column {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
 }