@import '../../assets/sass/variables';

.container-loading {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: rgba(255, 255, 255, 0.85);
    top: 0;
    left: 0;
    z-index: 1;
    p {
      color: $c-gr-3;
    }
    .loader  {
        animation: rotate 1s infinite;  
        height: 50px;
        width: 50px;
      }
      
      .loader:before,
      .loader:after {   
        border-radius: 50%;
        content: '';
        display: block;
        height: 20px;  
        width: 20px;
      }
      .loader:before {
        animation: ball1 1s infinite;  
        background-color: $c-1;
        box-shadow: 30px 0 0 $c-2;
        margin-bottom: 10px;
      }
      .loader:after {
        animation: ball2 1s infinite; 
        background-color: $c-3;
        box-shadow: 30px 0 0 $c-gr-3;
      }
      
      @keyframes rotate {
        0% { 
          -webkit-transform: rotate(0deg) scale(0.8); 
          -moz-transform: rotate(0deg) scale(0.8);
        }
        50% { 
          -webkit-transform: rotate(360deg) scale(1.2); 
          -moz-transform: rotate(360deg) scale(1.2);
        }
        100% { 
          -webkit-transform: rotate(720deg) scale(0.8); 
          -moz-transform: rotate(720deg) scale(0.8);
        }
      }
      
      @keyframes ball1 {
        0% {
          box-shadow: 30px 0 0 $c-gr-3;
        }
        50% {
          box-shadow: 0 0 0 $c-gr-3;
          margin-bottom: 0;
          -webkit-transform: translate(15px,15px);
          -moz-transform: translate(15px, 15px);
        }
        100% {
          box-shadow: 30px 0 0 $c-gr-3;
          margin-bottom: 10px;
        }
      }
      
      @keyframes ball2 {
        0% {
          box-shadow: 30px 0 0 $c-2;
        }
        50% {
          box-shadow: 0 0 0 $c-2;
          margin-top: -20px;
          -webkit-transform: translate(15px,15px);
          -moz-transform: translate(15px, 15px);
        }
        100% {
          box-shadow: 30px 0 0 $c-2;
          margin-top: 0;
        }
      }
}

