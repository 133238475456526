$c-wh: #fff;
$c-gr: #f5f5f5;
$c-gr-2: #dee2e6;
$c-gr-3: #797979;
$c-dr: #333;
$c-blue-o: #31bcd01c;
$c-alert-requiered: #e41818;
$c-alert-error: #e41818;
$c-alert-success: #6fc300;

// sura
$c-1: #0033a0;
$c-2: #00aec7;
$c-3: #e3e52b;

//Variantes SURA

$v-1: #ECF0F1;
$v-2: #768692;
$v-3: #8189A9;
$v-4: #53565A;

// df
$b-radius: 5px;

// sz
$sz-header: 70px;
$sz-tabs-ppal: 80px;

// font
$f-ppal:'FS Joey';
$f-second: 'Barlow';


//heigth
$sz-control: 40px;
$sz-control-textarea: 60px;
$sz-control-textarea-r: 10px;

// breakpoints responsive
$qry-mobile-vertical: 575px;
$qry-mobile-horizontal: 767px;
$qry-tablet: 991px;
$qry-screen: 1199px;
$qry-screen-big-min: 1200px;
