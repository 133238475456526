@import '../../assets/sass/variables';

.nav{
    &-tabs{
        &.tab-steps{
            min-height: $sz-tabs-ppal;
            max-height: $sz-tabs-ppal;
            background-color: $c-wh;
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
            display: flex;
            flex-wrap: nowrap;
            position: absolute;
            bottom: 0;
            width: 100%;
            z-index: 1;
            border-bottom: 1px solid $c-gr-2;

            > li{
                width: 100%;
                height: $sz-tabs-ppal;
                > a{
                    color: $c-1;
                    height: 100%;
                    border: 0;
                    border-radius: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-transform: uppercase;
                    font-size: 12px;
                    text-decoration: none;
                    cursor: pointer;
                    > .icon-list{
                        width: 45px;
                        height: 45px;
                        background-color: $c-blue-o;
                        border-radius: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 10px;
                        transition: 0.2s;
                        > .fa, .fal {
                            font-size: 25px;
                            color: $c-2;
                            padding: 0;
                        }
                        &:hover {
                            border: 1px solid $c-2;
                        }
                    }
                    &.active{
                        > .icon-list{
                            border: 1px solid $c-2;
                        }
                    }

                    &.save{
                        background-color: $c-1;
                        color: $c-wh;
                        font-size: 15px;
                        font-weight: 500;
                        height: 50px;
                        width: 55%;
                        margin: 15px auto;
                        border-radius: 50px;

                        > .fa, .fal {
                            font-size: 20px;
                            background-color: transparent;
                            color: $c-wh;
                            padding: 0;
                            margin-right: 10px;
                        }

                        &:hover {
                            background-color: #001f63;
                        }
                    }

                    &.disabled{
                        opacity: .5;
                        pointer-events: none;
                        cursor: not-allowed;
                    }
                }

                &.ready{
                    > a{
                        > svg{
                            background-color: $c-2;
                            color: $c-wh;
                            position: relative;
                        }
                        &::before{
                            content: '\f640';
                            display: block;
                            width: 20px;
                            height: 20px;
                            background-color: $c-3;
                            color: $c-wh;
                            position: absolute;
                            top: 15px;
                            left: 45px;
                            z-index: 9;
                            border-radius: 100%;
                            font-family: 'FontAwesome Pro', Arial, Helvetica, sans-serif;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $qry-mobile-vertical){
    .nav{
        &-tabs{
            &.tab-steps{
                min-height: $sz-tabs-ppal;
                max-height: $sz-tabs-ppal;
                background-color: $c-wh;
                box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
                display: flex;
                align-items: baseline;
                flex-wrap: nowrap;
                position: absolute;
                bottom: 0;
                width: 100%;
                z-index: 1;
                border-bottom: 1px solid $c-gr-2;
    
                > li{
                    width: 100%;
                    height: $sz-tabs-ppal;
                    > a{
                        color: $c-1;
                        height: 100%;
                        border: 0;
                        border-radius: 0;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        text-transform: uppercase;
                        font-size: 9px;
                        cursor: pointer;
                        > .icon-list{
                            width: 25px;
                            height: 25px;
                            background-color: $c-blue-o;
                            border-radius: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-right: 0;
                            margin-bottom: 5px;
                            transition: 0.2s;
                            > .fa, .fal {
                                font-size: 15px;
                                color: $c-2;
                                padding: 0;
                            }
                            &:hover {
                                border: 1px solid $c-2;
                            }
                        }
                        &.active{
                            > .icon-list{
                                border: 1px solid $c-2;
                            }
                        }
    
                        &.save{
                            background-color: $c-1;
                            color: $c-wh;
                            font-size: 9px;
                            font-weight: 500;
                            height: 50px;
                            width: 80%;
                            margin: 15px auto;
                            border-radius: 50px;
    
                            > .fa, .fal {
                                font-size: 14px;
                                background-color: transparent;
                                color: $c-wh;
                                padding: 0;
                                margin-right: 0;
                                margin-bottom: 5px;
                            }
                        }
    
                        &.disabled{
                            opacity: .5;
                        }
                    }
    
                    &.ready{
                        > a{
                            > svg{
                                background-color: $c-2;
                                color: $c-wh;
                                position: relative;
                            }
                            &::before{
                                content: '\f640';
                                display: block;
                                width: 20px;
                                height: 20px;
                                background-color: $c-3;
                                color: $c-wh;
                                position: absolute;
                                top: 15px;
                                left: 45px;
                                z-index: 9;
                                border-radius: 100%;
                                font-family: 'FontAwesome Pro', Arial, Helvetica, sans-serif;
                            }
                        }
                    }
                }
            }
        }
    }
}