@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300;500;700&display=swap');

@font-face {
    font-family: 'FS Joey';
    font-weight: 200;
    src: url('../fonts/fs-joey/FSJoey-Regular.ttf');
}
@font-face {
    font-family: 'FS Joey';
    font-weight: 400;
    src: url('../fonts/fs-joey/FSJoey-Medium.ttf');
}
@font-face {
    font-family: 'FS Joey';
    font-weight: 700;
    src: url('../fonts/fs-joey/FSJoey-Bold.ttf');
}

