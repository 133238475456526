@import '../variables.scss';

.flexForm {
  display: flex;

  > select,
  > .react-datepicker-wrapper,
  > .selectpicker {
    width: 85%;
    margin-right: 15px;
  }
  > input[type='textarea'] {
    width: 40%;
  }
  > input[type='text'] {
    width: 40%;
  }

  &.fSelect {
    > select {
      margin-right: 0;
      margin-left: 15px;
    }
  }
}

// controls
input {
  &[type='text'],
  &[type='number'],
  &[type='textarea'] {
    width: 100%;
    height: $sz-control;
    border-radius: $sz-control;
    padding: 0 15px;
    border: 1px solid rgba(0, 0, 0, 0.1);

    &:focus {
      border-color: $c-2;
    }

    &.minW {
      width: 170px !important;
      float: left;
    }
  }
}
textarea {
  resize: vertical;
  width: 98%;
  height: $sz-control-textarea;
  max-height: 90px;
  border-radius: $sz-control-textarea-r;
  padding: 5px 5px;
  margin: 15px 0;
  border: 1px solid rgba(0, 0, 0, 0.1);

  &:focus {
    border-color: $c-2;
  }

  &.minW {
    width: 170px !important;
    float: left;
  }
}
select {
  width: 100%;
  height: $sz-control;
  border-radius: $sz-control;
  padding: 0 10px;
  &.minW {
    width: 170px !important;
  }
}
.btn-next {
  display: inline-block !important;
  color: $c-wh !important;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}

.btn-addMore {
  width: 160px;
  height: $sz-control;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $c-2;
  color: $c-wh;
  border-radius: $sz-control;
  font-size: 16px;
  transition: all 0.2s;

  &:hover {
    color: $c-wh;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }
  > svg {
    font-size: 20px;
    margin-right: 10px;
  }
}

.btn-generic {
  width: auto;
  height: $sz-control;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  background-color: $c-2;
  color: $c-wh;
  border-radius: $sz-control;
  font-size: 14px;
  transition: all 0.2s;

  &:hover {
    color: $c-wh;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }
  > svg {
    font-size: 20px;
    margin-right: 10px;
  }
}

.form-check {
  display: flex;
}

form {
  width: 100%;
  .form-group {
    position: relative;
    .adition {
      position: absolute;
      top: 10px;
      left: 15px;
      font-size: 20px;
      opacity: 0.7;
    }
    label {
      font-size: 15px;
      color: $c-dr;
      font-weight: bold;
      margin-bottom: 7px;
      opacity: 0.8;
      > svg {
        margin-right: 10px;
        color: $c-2;
      }
    }

    .radioOptions {
      width: 100%;
      display: flex;
      height: $sz-control;
      position: relative;
      > .form-check {
        height: 100%;
        padding-left: 0;
        margin-left: 20px;
        display: flex;
        &:first-child {
          margin-left: 0;
        }
        > .form-check-label {
          height: 100%;
          display: flex;
          position: relative;
          padding-left: 25px;
          align-items: center;
          font-weight: 300;
          font-size: 14px;
          > input {
            &[type='radio'] {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              margin: 0;
              z-index: 8;
              opacity: 0;
              &:checked {
                + .control {
                  background-color: $c-2;
                  border-color: transparent;
                }

                ~ input {
                  &[type='text'] {
                    pointer-events: all;
                    cursor: text;
                    opacity: 1;
                  }
                }
              }
            }
            &[type='text'] {
              width: 100%;
              margin-left: 10px;
              z-index: 9;
              pointer-events: none;
              opacity: 0.6;
              cursor: not-allowed;
            }
          }

          > .control {
            width: 18px;
            height: 18px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: transparent;
            border: 2px solid rgba(0, 0, 0, 0.3);
            display: block;
            border-radius: 100%;
            transition: all 0.2s;
          }
        }
      }
    }
    .checkOptions {
      width: 100%;
      display: flex;
      height: $sz-control;
      position: relative;
      align-items: baseline;
      > .form-check {
        height: 100%;
        padding-left: 0;
        margin-left: 20px;
        margin-bottom: 10px;
        flex: 1 1 auto;
        &:first-child {
          margin-left: 0;
        }
        > .form-check-label {
          // height: 100%;
          display: flex;
          position: relative;
          padding-left: 25px;
          align-items: center;
          font-weight: 300;
          font-size: 14px;
          > input {
            &[type='checkbox'] {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              margin: 0;
              z-index: 8;
              opacity: 0;
              &:checked {
                + .control {
                  background-color: $c-2;
                  border-color: transparent;
                }

                ~ input {
                  &[type='text'] {
                    pointer-events: all;
                    cursor: text;
                    opacity: 1;
                  }
                }
              }
            }
            &[type='text'] {
              width: 100%;
              margin-left: 10px;
              z-index: 9;
              pointer-events: none;
              opacity: 0.6;
              cursor: not-allowed;
            }
          }

          > .control {
            width: 15px;
            height: 15px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: transparent;
            border: 2px solid rgba(0, 0, 0, 0.3);
            display: block;
            border-radius: $b-radius;
            transition: all 0.2s;
          }
        }
      }

      &.full {
        height: auto;
        flex-wrap: wrap;

        > .form-check {
          width: 100%;
          margin-left: 0 !important;
          margin-top: 10px;
        }
      }
      &.muchOptions {
        height: auto;
        flex-wrap: wrap;
        > .form-check {
          min-width: 30%;
          max-width: 30%;
          height: auto;
          margin-right: 15px;
          margin-left: 0;
          margin-top: 10px;
        }
      }
    }
    .second-options-check {
        margin-left: 25px;
        margin-bottom: 10px;
        display: flex;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        >li {
            margin-right: 5px;
        }
    }
    .table__list-column ul > li {
      width: 100%;
      display: flex;
      height: $sz-control;
      position: relative;
      > .form-check {
        height: 100%;
        padding-left: 0;
        margin-left: 20px;
        flex: 1 1 auto;
        &:first-child {
          margin-left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        > .form-check-label {
          height: 100%;
          display: flex;
          position: relative;
          align-items: center;
          justify-content: center;
          font-weight: 300;
          font-size: 14px;
          > input {
            &[type='radio'] {
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              margin: 0;
              z-index: 8;
              opacity: 0;
              &:checked {
                + .control {
                  background-color: $c-2;
                  border-color: transparent;
                }

                ~ input {
                  &[type='text'] {
                    pointer-events: all;
                    cursor: text;
                    opacity: 1;
                  }
                }
              }
            }
            &[type='text'] {
              width: 100%;
              margin-left: 10px;
              z-index: 9;
              pointer-events: none;
              opacity: 0.6;
              cursor: not-allowed;
            }
          }

          > .control {
            width: 18px;
            height: 18px;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: transparent;
            border: 2px solid rgba(0, 0, 0, 0.3);
            display: block;
            border-radius: 100%;
            transition: all 0.2s;
          }
        }
      }
    }
  }
}

li {
  list-style: none;
}

.alert-red {
  width: 100%;
  font-size: 13px;
  color: #e41818;
  line-height: normal;
  margin-top: 3px;
  display: flex;
  align-items: center;
  > svg {
    font-size: 13px;
    margin-right: 5px;
  }
}

.anho {
  width: 170px !important;
}

.btn {
  height: $sz-control;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $sz-control;
  border: 0;
  cursor: pointer;

  &.c-1 {
    background-color: $c-1;
    color: $c-wh;
  }
  &.c-wh {
    background-color: $c-wh;
    color: $c-1;
  }
}

// required
.required {
  > label {
    position: relative;
    &::after {
      content: '*';
      display: inline-block;
      clear: both;
      color: $c-2;
      line-height: 0;
      margin-left: 3px;
      vertical-align: middle;
      font-size: 20px;
      font-weight: bold;
    }
  }
}

// separated
.separated {
  padding-top: 25px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &.messageFinishStep {
    border-bottom: none;
    text-align: center;
  }
}

.pets {
  border-top: 1px solid $c-2;
  padding-top: 25px;
  top: 4px;
}

.form-group {
  > input {
    &.form-control {
      &:disabled,
      &:read-only {
        background-color: #e9ecef;
        border-color: rgba(0, 0, 0, 0.1);
        color: #495057;
      }
    }
  }
}

@media (max-width: $qry-mobile-vertical) {
  textarea {
    width: 97%;

    &:focus {
      border-color: $c-2;
    }

    &.minW {
      width: 170px !important;
      float: left;
    }
  }
  form {
    width: 100%;
    .form-group {
      .second-options-check {
        flex-wrap: wrap;
        >li {
            margin-right: 5px;
            height: auto !important;
            text-align: left !important;
        }
      }
      .table__list-column ul > li {
        width: 100%;
        margin-right: 18px;
        display: flex;
        height: 65px;
        text-align: center;
        position: relative;
        > .form-check {
          height: 100%;
          padding-left: 0;
          margin-left: 20px;
          flex: 1 1 auto;
          &:first-child {
            margin-left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          > .form-check-label {
            height: 100%;
            display: flex;
            position: relative;
            align-items: center;
            justify-content: center;
            font-weight: 300;
            font-size: 14px;
          }
        }
      }
    }
  }
}
